import React, { useState } from 'react';
import { Carousel, Container, Row, Col, Button } from "react-bootstrap";
import ImageDanke from '../../images/home/danke.jpg';
import { ReactComponent as ImageFlickrLogo } from '../../images/flickr-logo.svg';
import { ReactComponent as ImageCaretRight } from '../../images/caret-right-fill.svg';

function Homeslider() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      <img src={ImageDanke} alt="Danke" className='img-fluid d-lg-none' />
      <Carousel fade activeIndex={index} controls={false} indicators={false} onSelect={handleSelect}>
        <Carousel.Item id="HomesliderDanke" style={{ backgroundImage: `url(${ImageDanke})` }}>
          <Container>
            <Row className=''>
              <Col lg="8" xl="6">
                <h1 className='text-center pb-4 pt-5 mt-lg-5'>Vielen Dank!</h1>
                <p className='mb-5'>
                  Die Fachpressetage 2025 waren wieder ein lebhafter Treffpunkt für Industrie und Fachpresse.
                  Danke an alle Beteiligten für die interessanten Themen und die freundlichen Begegnungen!<br /><br />
                  <strong>Die nächsten Fachpressetage sind am 03./04.02.2026 geplant.</strong> Verpassen Sie keine Fristen und melden
                  Sie schon jetzt eine <a href="mailto:kontakt@fachpressetage.de">Teilnahme-Option</a> an!</p>
                <div className='text-center'>
                  <Button
                    href='https://app.fachpresse.live'
                    target='blank'
                    variant='rbs-button-orange-inverse mb-2 rbs-action-buttons'
                    size='lg'>
                    Downloads, Videos, ... *
                  </Button>
                  <p className='small mb-4'>
                    * Teilnehmer-Portal (Login erforderlich)
                  </p>

                  <Button
                    variant='rbs-button-orange rbs-action-buttons'
                    size='lg'
                    href="/fotos"
                  >
                    <ImageCaretRight className="ImageCaretRight" width={20} height={20} fill='#fff' />
                    &nbsp;Bilder der Veranstaltung
                  </Button>
                  <br /><br /><br /><br />
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      </Carousel>
    </React.Fragment>
  );
}

export default Homeslider;