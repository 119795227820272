import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageAnmeldungPlaceholder from '../../images/anmeldung/anmeldung-dummy.png';

function Vortrag() {

    const ImagesVortrag = [
        require('../../images/anmeldung/vortrag-01.jpg'),
        require('../../images/anmeldung/vortrag-02.jpg'),
        require('../../images/anmeldung/vortrag-03.jpg'),
        require('../../images/anmeldung/vortrag-04.jpg'),
        require('../../images/anmeldung/vortrag-05.jpg'),
        require('../../images/anmeldung/vortrag-06.jpg'),
        require('../../images/anmeldung/vortrag-07.jpg'),
        require('../../images/anmeldung/vortrag-08.jpg'),
        require('../../images/anmeldung/vortrag-09.jpg'),
        require('../../images/anmeldung/vortrag-10.jpg'),
        require('../../images/anmeldung/vortrag-11.jpg'),
        require('../../images/anmeldung/vortrag-12.jpg'),
    ]

    const ImagesVortragElement = ImagesVortrag[(Math.floor(Math.random() * ImagesVortrag.length))];

    return (
        <React.Fragment>
            <div className='text-center'>
                <img src={ImageAnmeldungPlaceholder} className='d-lg-none img-fluid ImageAnmeldung' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesVortragElement}")` }} />
            </div>
            <div className="ImageVortrag" style={{ backgroundImage: `url("${ImagesVortragElement}")` }}>
                <Container>
                    <Row className='pt-lg-4 justify-content-lg-end'>
                        <Col xs={12} lg={6}>
                            <h1 className='pt-5 pb-3'>Vortrags-Option für 2026 anmelden</h1>
                            <p>
                                Die nächsten Fachpressetage sind am 03. und 04. Februar 2026 im <strong>GenoHotel in Karlsruhe</strong> geplant. 
                                Wenn Sie keine Fristen verpassen möchten, können Sie bereits jetzt eine Option für die Multi-Pressekonferenz oder einen 
                                Pressegesprächs-Tisch anmelden. Bei einem Überhang entscheidet das Anmeldedatum. Eine frühzeitige Anmeldung lohnt sich also.
                                <br /><br />
                                Welche der eingereichten Vortragsthemen ins Programm aufgenommen werden, entscheiden die eingeladenen Journalistinnen und
                                Journalisten in einer Online-Abstimmung. Berücksichtigt werden alle Abstracts, die</p>
                            <p className='text-center'>
                                <strong>bis zum 24. Oktober 2025</strong>
                            </p>
                            <p className='pb-lg-5'>
                                eingereicht wurden. Bewerber um einen Vortragsplatz können daher alternativ einen Messetisch buchen für den Fall, dass der 
                                Vortrag abgelehnt werden sollte.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Vortrag;