import React from 'react';

import RbsPage from '../controls/RbsPage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageFotosPlaceholder from '../../images/fotos/fotos-placeholder.png';


const Fotos = (props) => {

  const Fotos2025 = [
    require('../../images/fotos/fpt-fotos-2025-1.jpg'),
    require('../../images/fotos/fpt-fotos-2025-2.jpg'),
    require('../../images/fotos/fpt-fotos-2025-3.jpg'),
    require('../../images/fotos/fpt-fotos-2025-4.jpg')
  ]

  const ImagesFotos2025Element = Fotos2025[(Math.floor(Math.random() * Fotos2025.length))];

  const Fotos2024 = [
    require('../../images/fotos/fpt-fotos-2024-1.jpg'),
    require('../../images/fotos/fpt-fotos-2024-2.jpg'),
    require('../../images/fotos/fpt-fotos-2024-3.jpg'),
    require('../../images/fotos/fpt-fotos-2024-4.jpg'),
    require('../../images/fotos/fpt-fotos-2024-5.jpg'),
    require('../../images/fotos/fpt-fotos-2024-6.jpg'),
  ]

  const ImagesFotos2024Element = Fotos2024[(Math.floor(Math.random() * Fotos2024.length))];

  const Fotos2023 = [
    require('../../images/fotos/fpt-fotos-2023-1.jpg'),
    require('../../images/fotos/fpt-fotos-2023-2.jpg'),
    require('../../images/fotos/fpt-fotos-2023-3.jpg'),
    require('../../images/fotos/fpt-fotos-2023-4.jpg')
  ]

  const ImagesFotos2023Element = Fotos2023[(Math.floor(Math.random() * Fotos2023.length))];

  const Fotos2022 = [
    require('../../images/fotos/fpt-fotos-2022-1.jpg'),
    require('../../images/fotos/fpt-fotos-2022-2.jpg'),
    require('../../images/fotos/fpt-fotos-2022-3.jpg')
  ]
  
  const ImagesFotos2022Element = Fotos2022[(Math.floor(Math.random() * Fotos2022.length))];

  const Fotos2020 = [
    require('../../images/fotos/fpt-fotos-2020-1.jpg'),
    require('../../images/fotos/fpt-fotos-2020-2.jpg'),
    require('../../images/fotos/fpt-fotos-2020-3.jpg'),
    require('../../images/fotos/fpt-fotos-2020-4.jpg')
  ]

  const ImagesFotos2020Element = Fotos2020[(Math.floor(Math.random() * Fotos2020.length))];

  const Fotos2019 = [
    require('../../images/fotos/fpt-fotos-2019-1.jpg'),
    require('../../images/fotos/fpt-fotos-2019-2.jpg'),
    require('../../images/fotos/fpt-fotos-2019-3.jpg'),
    require('../../images/fotos/fpt-fotos-2019-4.jpg')
  ]

  const ImagesFotos2019Element = Fotos2019[(Math.floor(Math.random() * Fotos2019.length))];

  const onOpen2025Click = () => {
    window.open('https://lightroom.adobe.com/shares/d237579a4f324478b41fd78d75aeaae5', '_blank');
  }
  const onOpen2024Click = () => {
    window.open('https://lightroom.adobe.com/shares/def3b27d9b6c40908003cd8c4658bc2e', '_blank');
  }
  const onOpen2023Click = () => {
    window.open('https://www.flickr.com/photos/140184022@N05/albums/72177720305892697', '_blank');
  }
  const onOpen2022Click = () => {
    window.open('https://www.flickr.com/photos/140184022@N05/albums/72177720297675481', '_blank');
  }
  const onOpen2020Click = () => {
    window.open('https://www.flickr.com/photos/140184022@N05/albums/72157713010968322', '_blank');
  }
  const onOpen2019Click = () => {
    window.open('https://www.flickr.com/photos/140184022@N05/albums/72157706633135794', '_blank');
  }

  return (
    <RbsPage>

      <section id="Fotos" className="custom-section-grey scrollIntoViewFix">

        <Container fluid className='pb-5'>
          <Row className='pt-3 justify-content-center'>
            <Col className='text-end'>
              <h1 className="text-center pt-2 pb-5 mb-4">
                Fotos von den Fachpressetagen
              </h1>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-lg-6 col-xl-4">
            <div className={`CardWrapper FotosCardwrapper mx-auto mb-5`} onClick={onOpen2025Click}>
                <img src={ImageFotosPlaceholder} className='img-fluid' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesFotos2025Element}")` }} />
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2025</h2>
                  <p>04. und 05. Februar 2025</p>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
            <div className={`CardWrapper FotosCardwrapper mx-auto mb-5`} onClick={onOpen2024Click}
              >
                <img src={ImageFotosPlaceholder} className='img-fluid' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesFotos2024Element}")` }} />
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2024</h2>
                  <p>06. und 07. Februar 2024</p>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
            <div className={`CardWrapper FotosCardwrapper mx-auto mb-5`} onClick={onOpen2023Click}
              >
                <img src={ImageFotosPlaceholder} className='img-fluid' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesFotos2023Element}")` }} />
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2023</h2>
                  <p>07. und 08. Februar 2023</p>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
            <div className={`CardWrapper FotosCardwrapper mx-auto mb-5`} onClick={onOpen2022Click}
              >
                <img src={ImageFotosPlaceholder} className='img-fluid' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesFotos2022Element}")` }} />
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2022</h2>
                  <p>23. und 24. März 2022</p>
                </div>
              </div>              
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
            <div className={`CardWrapper FotosCardwrapper mx-auto mb-5`} onClick={onOpen2020Click}
              >
                <img src={ImageFotosPlaceholder} className='img-fluid' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesFotos2020Element}")` }} />
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2020</h2>
                  <p>04. bis 06. Februar 2020</p>
                </div>
              </div>
              
            </Col>
            <Col className="col-12 col-lg-6 col-xl-4">
            <div className={`CardWrapper FotosCardwrapper mx-auto mb-5`} onClick={onOpen2019Click}
              >
                <img src={ImageFotosPlaceholder} className='img-fluid' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesFotos2019Element}")` }} />
                <div className="CardFotoOverlay">
                  <h2>Fachpressetage 2019</h2>
                  <p>05. und 06. Februar 2019</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </RbsPage>
  )

}

export default Fotos;
