import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageAnmeldungPlaceholder from '../../images/anmeldung/anmeldung-dummy.png';

function Tisch() {

    const ImagesTisch = [
        require('../../images/anmeldung/tisch-01.jpg'),
        require('../../images/anmeldung/tisch-02.jpg'),
        require('../../images/anmeldung/tisch-03.jpg'),
        require('../../images/anmeldung/tisch-04.jpg'),
        require('../../images/anmeldung/tisch-05.jpg'),
        require('../../images/anmeldung/tisch-06.jpg'),
        require('../../images/anmeldung/tisch-07.jpg'),
        require('../../images/anmeldung/tisch-08.jpg')
    ]

    const ImagesTischElement = ImagesTisch[(Math.floor(Math.random() * ImagesTisch.length))];

    return (
        <React.Fragment>
            <div className='text-center'>
                <img src={ImageAnmeldungPlaceholder} className='d-lg-none img-fluid ImageAnmeldung' alt='Multi-Pressekonferenz' style={{ backgroundImage: `url("${ImagesTischElement}")` }} />
            </div>
            <div className="ImageTisch" style={{ backgroundImage: `url("${ImagesTischElement}")` }}>
                <Container>
                    <Row className='pt-lg-4'>
                        <Col xs={12} lg={6}>
                            <h1 className='pt-5 pb-3'>Pressegesprächs-Tisch buchen</h1>
                            <p>
                                Die nächsten Fachpressetage sind am 03. und 04. Februar 2026 im <strong>GenoHotel in Karlsruhe</strong> geplant.
                                Die Gesprächstische sind auf 10 Plätze begrenzt und werden in der Reihenfolge der Anmeldung vergeben.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Tisch;